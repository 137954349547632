import React, { Component } from 'react';
import { Provider, observer } from 'mobx-react';
import i18n from 'i18next';
import { withTranslation, initReactI18next } from 'react-i18next';
import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarNavigationIcon,
  // TopAppBarActionItem,
} from '@rmwc/top-app-bar';
import { Icon } from '@rmwc/icon';
import {
  Drawer, DrawerHeader, DrawerContent, DrawerTitle,
} from '@rmwc/drawer';
import { List, ListItem } from '@rmwc/list';
import { Avatar } from '@rmwc/avatar';
import { Select } from '@rmwc/select';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { pdfjs } from 'react-pdf';

// Icons for navigation
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';
// import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined';
// import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import HealingOutlinedIcon from '@material-ui/icons/HealingOutlined';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import { ReactComponent as FactoryOutlinedIcon } from '../assets/images/factory_outlined.svg';
// import { ReactComponent as BadgeIcon } from '../assets/images/badge.svg';
// import { ReactComponent as EngineerIcon } from '../assets/images/resources.svg';
import { ReactComponent as InventoryIcon } from '../assets/images/products.svg';
import { ReactComponent as ReceiptLong } from '../assets/images/receipt-long.svg';
import { ReactComponent as CertificateIcon } from '../assets/images/certificate.svg';
import { ReactComponent as SideBarIcon } from '../assets/images/sidebar.svg';

import Calendar from '../calendar';
import Chat from '../messages/Chat';
import Chats from '../messages';
import BulletinBoardCategoryView from '../bulletin-board-posts/employee/BulletinBoardCategoryView';
import ChatStore from '../messages/stores/ChatStore';
import Invitation from '../invitations';
import Login from '../Login';
import LoginStore from '../Login/LoginStore';
import ChangePassword from '../Login/ChangePassword';
import logo from '../logo.svg';
import smallLogo from '../assets/images/waiting.svg';
import Profile from '../profile';
import Registration from '../registration';
import EmployerWorkOrders from '../employer-work-orders';
import EmployerWorkOrderDetail from '../employer-work-orders/EmployerWorkOrderDetail';
import EmployerTimelog from '../employer-timelog';
import EmployerWorkOrderTrips from '../employer-work-order-trips';
import EmployerBilling from '../employer-billing';
import LocationsPurchasers from '../locations-purchasers';
import Safety from '../safety';
import AccidentReports from '../safety/AccidentReports';
import RegistrationStore from '../registration/RegistrationStore';
import requestsConfig from './requests';
import startRouter from '../router';
import Theme from '../Theme';
import Timelog from '../timelog';
import TimelogStore from '../timelog/TimelogStore';
import EmployerContextStore from '../employer-timelog/EmployerContextStore';
import EmployerWorkOrderStore from '../employer-work-orders/EmployerWorkOrderStore';
import WorkTaskStore from '../employer-work-orders/work-tasks/WorkTaskStore';
import translationFI from '../i18n/fi.json';
import UiStore from '../stores/UiStore';
import WorkOrderDetail from '../calendar/WorkOrderDetail';
import WorkOrderStore from '../calendar/WorkOrderStore';
import WorkOrderTripStore from '../timelog/WorkOrderTripStore';
import LocationStore from '../stores/LocationStore';
import PurchaserStore from '../stores/PurchaserStore';
import EmployerUserStore from '../stores/EmployerUserStore';
// import * as pushSw from '../push-service-worker';
// import subscribeUser from '../push-notification-subscription';
import Error from '../error-handling/Error';
import ErrorStore from '../error-handling/ErrorStore';
import ProductStore from '../stores/ProductStore';
import ValidityStore from '../stores/ValidityStore';
import ReportStore from '../stores/ReportStore';
import CertificateStore from '../stores/CertificateStore';
import AbsenceStore from '../stores/AbsenceStore';
import GlobalSnackbarStore from '../stores/GlobalSnackbarStore';
import BulletinBoardStore from '../stores/BulletinBoardStore';

import Header from './Header';
import BillingAttachments from '../employer-billing/BillingAttachments';
// import BillingReports from '../employer-billing/BillingReports';
import BillingReportsHourlyIndex from '../employer-billing/BillingReportsHourlyIndex';
import BillingReportsDailyIndex from '../employer-billing/BillingReportsDailyIndex';
import ProductReport from '../employer-billing/ProductReport';
import EmployerAbsences from '../employer-absences';
import WorkHourReport from '../timelog-report';
import BulletinBoardPosts from '../bulletin-board-posts/employer';

// import PayrollAdministrationStore from '../employer-mode/payroll-administration/PayrollAdministrationStore';
// import PayrollAdministration from '../employer-mode/payroll-administration';

import ActionCableStore from '../stores/ActionCableStore';
import ResourceAvailability from '../resource-availability/ResourceAvailability';
import Employees from '../employees/Employees';
import CloseCallReportStore from '../stores/CloseCallReportStore';
import EmployerCloseCallReports from '../safety/EmployerCloseCallReports';
import AccidentReportStore from '../safety/AccidentReportStore';
import Products from '../products/Products';
import Certificates from '../certificates';
import SalaryPeriodProcessingIndex from '../salary-period-processing';
import SalaryPeriodStore from '../stores/SalaryPeriodStore';

// Mozilla.github: latest version?
// pdfjs.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.mjs';
// Version 2.12.313 matches the installed pdfjs-dist version: https://cdnjs.com/libraries/pdf.js/2.12.313
pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.min.js';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'fi',
    interpolation: {
      escapeValue: false,
    },
    lng: 'fi',
    resources: {
      fi: {
        translation: translationFI,
      },
    },
  });

const stores = {};
const requests = requestsConfig(
  {
    API_ROOT: '',
  },
  stores,
);

const uiStore = new UiStore(requests);
const errorStore = new ErrorStore();
const actionCableStore = new ActionCableStore();
const productStore = new ProductStore(uiStore, requests);
const certificateStore = new CertificateStore(uiStore, requests);
const absenceStore = new AbsenceStore(uiStore, requests);
const validityStore = new ValidityStore(uiStore, requests);
const employerContextStore = new EmployerContextStore(requests);
const timelogStore = new TimelogStore(uiStore, actionCableStore, employerContextStore, requests);
const workOrderTripStore = new WorkOrderTripStore(uiStore, requests, timelogStore, actionCableStore);
const loginStore = new LoginStore(uiStore, productStore, requests, errorStore, actionCableStore, timelogStore, workOrderTripStore);

stores.chatStore = new ChatStore(uiStore, requests);
stores.loginStore = loginStore;
stores.registrationStore = new RegistrationStore(requests, uiStore, loginStore);
stores.uiStore = uiStore;
stores.productStore = productStore;
stores.certificateStore = certificateStore;
stores.absenceStore = absenceStore;
stores.validityStore = validityStore;
stores.employerContextStore = employerContextStore;
stores.timelogStore = timelogStore;
stores.employerWorkOrderStore = new EmployerWorkOrderStore(uiStore, loginStore, requests);
stores.workTaskStore = new WorkTaskStore(uiStore, requests, stores.employerWorkOrderStore, actionCableStore);
// A hack to get around the fact that both UiStore and EmployerWorkOrderStore instances need to call each other
// The reason this is needed is because of employer-work-orders optimization that is related to routing, see UiStore.showEmployerWorkOrders()
// LoginStore needs the EmployerWorkOrderStore for when opening the employer-work-orders view when login is not yet complete
stores.uiStore.employerWorkOrderStore = stores.employerWorkOrderStore;
stores.loginStore.employerWorkOrderStore = stores.employerWorkOrderStore;
stores.workOrderStore = new WorkOrderStore(uiStore);
stores.workOrderTripStore = workOrderTripStore;
stores.errorStore = errorStore;
// stores.payrollAdministrationStore = new PayrollAdministrationStore(uiStore, requests);
stores.actionCableStore = actionCableStore;
stores.closeCallReportStore = new CloseCallReportStore(requests);
stores.accidentReportStore = new AccidentReportStore(requests);
stores.locationStore = new LocationStore(uiStore, requests);
stores.purchaserStore = new PurchaserStore(uiStore, requests);
stores.employerUserStore = new EmployerUserStore(uiStore, requests);
stores.reportStore = new ReportStore(requests);
stores.salaryPeriodStore = new SalaryPeriodStore(requests);
stores.globalSnackbarStore = new GlobalSnackbarStore();
stores.bulletinBoardStore = new BulletinBoardStore(requests);

// For easier debugging
window.__STORES__ = stores;

startRouter(stores.uiStore);

// TODO: fixme, hack
loginStore.isLoggingIn = true;

const handleNewMessage = (response) => {
  const chatId = response.body.chat_id;
  const messageId = response.body.message_id;
  const foundChat = stores.chatStore.rooms.value.find((room) => room.id === chatId);

  if (foundChat) {
    if (stores.uiStore.currentView.name === 'messages') {
      stores.chatStore.getNewMessage(chatId, messageId);
    }
    stores.chatStore.unreadCounts();
  } else {
    stores.chatStore.unreadCounts();
  }
};

const setNewMessageChannel = () => {
  if (actionCableStore.cableOn) {
    actionCableStore.subscribe('NewMessageChannel', handleNewMessage);
  }
};

const setEmployeeWorkHoursChannel = () => {
  stores.timelogStore.subscribe();
};

const setEmployeeWorkOrderTripsChannel = () => {
  stores.workOrderTripStore.subscribe();
};

const setEmployeeWorkTasksChannel = () => {
  stores.workTaskStore.subscribe();
};

const afterLoginSuccess = () => {
  setNewMessageChannel();
  stores.chatStore.unreadCounts();
};

// loginStore.findMe(afterLoginSuccess).then(
//   action(() => {
//     loginStore.isLoggingIn = false;
//     loginStore.isLoggedIn = true;
//   }),
// );

// Apparently this is here to prevent some kind of loop in the login screen, so not commenting this out
// This checks if there is a possibility to logUNin automatically, after pageload.
loginStore.findMe(afterLoginSuccess);

@observer
class App extends Component {
  constructor() {
    super();
    this.uiStore = stores.uiStore;
    this.chatStore = stores.chatStore;
    this.globalSnackbarStore = stores.globalSnackbarStore;
  }

  /* componentDidMount() {
    if (this.isPushNotificationSupported) {
      this.askUserPermission().then((response) => {
        console.log('response:', response);
        if (response === 'granted') {
          console.log('creating sub');
          // this.registerServiceWorker().then(() => {
          pushSw.register2(); // .then(() => {
          // console.log('registered');
          // this.createNotificationSubscription().then((sub) => { console.log('sub', sub); });
          // });
        }
      });
    }
  } */

  componentDidMount() {
    this.toggleMobileMode();
    window.addEventListener('resize', this.toggleMobileMode);
    // subscribeUser();
    // Check for sub / permission
    // console.log('sw', navigator.serviceWorker);
  }

  // registerServiceWorker = () => navigator.serviceWorker.register('../../push-service-worker.js')

  onTabSelected = (event, drawerTabIndex) => {
    const store = this.uiStore;
    const { currentUser: { accountInfo: { workOrderTripsEnabled } } } = store;
    const { index } = event.detail;

    if (store.employerMode && store.currentUser?.role === 'employer') {
      switch (drawerTabIndex) {
        case 1:
          switch (index) {
            case 0:
              return store.showEmployerWorkOrders();
            case 1:
              return store.showEmployerTimelogs();
            case 2:
              if (workOrderTripsEnabled) {
                return store.showEmployerWorkOrderTrips();
              }
              // There should only be tabs 0 and 1 if neither employers or trips are enabled
              return store.showEmployerAbsences();
            case 3:
              return store.showEmployerAbsences();
            default:
              return null;
          }
        case 2:
          switch (index) {
            case 0:
              return store.showEmployerBilling();
            case 1:
              return store.showBillingReports();
            default:
              return null;
          }
        // case 3:
        //   switch (index) {
        //     case 0:
        //       return store.showPayrollAdministration();
        //     default:
        //       return null;
        //   }
        default:
          return null;
      }
    } else {
      switch (index) {
        case 0:
          return store.showCalendar();
        case 1:
          return store.showProfile();
        case 2:
          return store.showTimeEntries();
        case 3:
          return store.showMessages();
        default:
          return store.showCalendar();
      }
    }
  };

  onSafetyTabSelected = (event) => {
    const store = this.uiStore;
    const { index } = event.detail;
    switch (index) {
      case 0:
        return store.showSafety();
      case 1:
        return store.showEmployerCloseCallReports();
      default:
        return store.showSafety();
    }
  }

  afterRegistration = () => {
    this.uiStore.showLogin();
  };

  // TODO: Finalize login/authentication
  afterLogin = (user) => {
    if (user.role === 'employer') {
      this.uiStore.showEmployerWorkOrders();
    } else {
      this.uiStore.showCalendar();
    }

    if (!actionCableStore.cable) {
      actionCableStore.setCable();
      setNewMessageChannel();
      setEmployeeWorkHoursChannel();
      setEmployeeWorkOrderTripsChannel();
      setEmployeeWorkTasksChannel();
      // call unreadcounts here
      this.chatStore.unreadCounts();
    }
  };

  // refreshData = () => {
  //   uiStore.refreshing = true;
  //   // loginStore.findMe();
  //   const findMe = Promise.resolve(loginStore.findMe(null, true));
  //   let refresh;

  //   switch (this.uiStore.currentView.name) {
  //     case 'calendar':
  //       break;
  //     case 'work-order':
  //       break;
  //     case 'profile':
  //       break;
  //     case 'invitation':
  //       break;
  //     case 'chat-room':
  //       break;
  //     case 'registration':
  //       break;
  //     case 'messages':
  //       break;
  //     case 'timelog':
  //       // stores.timelogStore.refresh();
  //       refresh = Promise.resolve(stores.timelogStore.refresh());
  //       break;
  //     default:
  //     /* noop */
  //   }
  //   // this.chatStore.unreadCounts();

  //   // For the small spinning animation for the refresh button
  //   const unreadCounts = Promise.resolve(this.chatStore.unreadCounts());
  //   Promise.all([findMe, refresh, unreadCounts]).then(() => {
  //     uiStore.refreshing = false;
  //   });
  // }

  refreshPage = () => {
    window.location.reload(true);
  }


  // eslint-disable-next-line class-methods-use-this
  toggleMobileMode() {
    // Maybe move the compared size into an uiStore variable
    const isMobile = window.innerWidth < 800;
    uiStore.setMobileMode(isMobile);
  }

  // isPushNotificationSupported = () => 'serviceWorker' in navigator && 'PushManager' in window

  // eslint-disable-next-line class-methods-use-this
  /* async askUserPermission() {
    // eslint-disable-next-line no-undef
    return Notification.requestPermission();
  } */

  renderApp = () => {
    let contentView;
    const view = this.uiStore.currentView;
    const isMenuLocked = uiStore.employerMiniMenuLocked;
    const { currentUser } = this.uiStore;
    const { open: snackbarOpen, message: snackbarMessage, onClose: snackbarOnClose } = this.globalSnackbarStore;

    // For now views, which don't require login don't need
    // the header
    let header = null;
    let tabIndex;
    let drawerTabIndex;

    const contentClasses = ['app-content'];
    // Reduce the top margin to compensate for the missing header e.g. in registration
    // if (!view.needsHeader) {
    if (this.uiStore.mobileMode) {
      contentClasses.push(`app-content-margin-top-${view.headerMarginMobile || 'default'}`);
    } else {
      // Desktop mode
      contentClasses.push(`app-content-margin-top-${view.headerMargin || 'default'}`);
    }

    // Adds the class only in employerview and if there is a current user
    if (view.employerView && !this.uiStore.hasNoCurrentUser()) {
      contentClasses.push('app-content-white');
    }

    // if (view.employerView && !view.needsHeader) {
    //   contentClasses.push('app-content-employer-small-margin-top');
    // }

    if (view.secured === true && (!this.uiStore.canView(view) || this.uiStore.hasNoCurrentUser())) {
      contentClasses.push('app-content-small-margin-top');
      console.log(`====> View ${view.name} needs authentication first`);
      contentView = <Login afterLogin={this.afterLogin} />;
      header = '';
    } else {
      // contentClasses = ['app-content'];
      console.log(`====> View ${view.name} needs header: ${view.needsHeader}`);
      console.log(`====> Trying to view ${view.name}, secured: ${view.secured}`);

      switch (view.name) {
        case 'calendar':
          contentView = <Calendar />;
          tabIndex = 0;
          break;
        case 'work-order':
          header = (
            <TopAppBar>
              <TopAppBarRow style={{ justifyContent: 'center' }}>
                <TopAppBarSection style={{ maxWidth: '800px', paddingLeft: '6px' }}>
                  <TopAppBarNavigationIcon
                    onClick={() => window.history.back()}
                    icon="arrow_back"
                  />
                </TopAppBarSection>
              </TopAppBarRow>
            </TopAppBar>
          );
          contentView = <WorkOrderDetail />;
          tabIndex = 0;
          break;
        case 'profile':
          contentView = <Profile />;
          tabIndex = 1;
          break;
        case 'invitation':
          contentView = <Invitation />;
          tabIndex = 0;
          break;
        case 'chat-room':
          contentView = <Chat />;
          tabIndex = 3;
          break;
        case 'bulletin-board-category':
          contentView = <BulletinBoardCategoryView />;
          tabIndex = 3;
          break;
        case 'registration':
          contentView = (
            <Registration afterRegistration={this.afterRegistration} />
          );
          tabIndex = 0;
          break;
        case 'messages':
          contentView = <Chats />;
          tabIndex = 3;
          break;
        case 'timelog':
          contentView = <Timelog />;
          tabIndex = 2;
          break;
        /* EMPLOYER VIEWS */
        case 'employer-work-orders':
          contentView = <EmployerWorkOrders />;
          tabIndex = 0;
          drawerTabIndex = 1;
          break;
        case 'employer-timelogs':
          contentView = <EmployerTimelog />;
          tabIndex = 1;
          drawerTabIndex = 1;
          break;
        case 'timelog-report':
          contentView = <WorkHourReport />;
          tabIndex = 0;
          drawerTabIndex = 2;
          break;
        case 'employer-work-order-trips':
          contentView = <EmployerWorkOrderTrips />;
          tabIndex = 2;
          drawerTabIndex = 1;
          break;
        case 'employer-absences':
          contentView = <EmployerAbsences />;
          tabIndex = currentUser?.accountInfo?.workOrderTripsEnabled ? 3 : 2;
          drawerTabIndex = 1;
          break;
        case 'resource-availability':
          contentView = <ResourceAvailability />;
          tabIndex = 0;
          break;
        case 'employer-work-order':
          contentView = <EmployerWorkOrderDetail />;
          tabIndex = 0;
          drawerTabIndex = 1;
          break;
        case 'employer-billing':
          contentView = <EmployerBilling />;
          tabIndex = 0;
          drawerTabIndex = 2;
          break;
        case 'employer-billing-attachments':
          contentView = <BillingAttachments />;
          tabIndex = 0;
          drawerTabIndex = 2;
          break;
        case 'employer-billing-reports':
          contentView = <BillingReportsDailyIndex />;
          tabIndex = 1;
          drawerTabIndex = 2;
          break;
        case 'employer-billing-reports-hourly':
          contentView = <BillingReportsHourlyIndex />;
          tabIndex = 1;
          drawerTabIndex = 2;
          break;
        case 'employer-product-report':
          contentView = <ProductReport />;
          tabIndex = 0;
          break;
        case 'locations-purchasers':
          contentView = <LocationsPurchasers />;
          tabIndex = 0;
          break;
        case 'employees':
          contentView = <Employees />;
          tabIndex = 0;
          break;
        case 'products':
          contentView = <Products />;
          tabIndex = 0;
          break;
        case 'safety':
          contentView = <Safety />;
          tabIndex = 0;
          drawerTabIndex = 1;
          break;
        case 'employer-close-call-reports':
          contentView = <EmployerCloseCallReports />;
          tabIndex = 1;
          drawerTabIndex = 1;
          break;
        case 'accident-reports':
          contentView = <AccidentReports />;
          tabIndex = 0;
          drawerTabIndex = 2;
          break;
        case 'salary-period-processing':
          contentView = <SalaryPeriodProcessingIndex />;
          tabIndex = 0;
          break;
        case 'certificates':
          contentView = <Certificates />;
          tabIndex = 0;
          break;
        case 'reset-password':
          contentView = <ChangePassword token={view.resetPasswordToken} />;
          tabIndex = 0;
          break;
        case 'bulletin-board-posts':
          contentView = <BulletinBoardPosts />;
          tabIndex = 0;
          break;
        default:
          contentView = null;
      }
    }

    const accountSelectOptions = uiStore.currentUser?.accounts?.reduce((optionsObj, account) => {
      // eslint-disable-next-line no-param-reassign
      optionsObj[account.id] = account.name;
      return optionsObj;
    }, {});

    const accountsWithoutSalaryPeriodProcessing = [2, 4, 5, 6, 7, 41, 42, 43, 45, 46, 47, 48];

    return (
      <>
        { errorStore.hasError && <Error />}
        {/* MOBILE HEADER */}
        { (uiStore.mobileMode || !uiStore.employerMode) && (
          <TopAppBar style={{ alignItems: 'center' }}>
            <TopAppBarRow style={{ maxWidth: '800px' }}>
              <TopAppBarSection alignStart>
                {uiStore.currentUser && (
                  <Icon
                    alt="menu"
                    className="cursor-pointer"
                    icon="menu"
                    onClick={() => uiStore.setMenuOpen(true)}
                    // {...uiStore.refreshing === true && { id: 'refresh' }}
                    style={{ marginLeft: '1em' }}
                    // id="refresh"
                  />
                )}
                {/* {uiStore.currentUser && uiStore.currentUser?.role !== 'employer'
                  && (
                    <Icon
                      alt="refresh"
                      className="cursor-pointer"
                      icon="refresh"
                      onClick={() => this.refreshData()}
                      {...uiStore.refreshing === true && { id: 'refresh' }}
                      style={{ marginLeft: '1em' }}
                    // id="refresh"
                    />
                  )}
                {uiStore.currentUser?.role === 'employer'
                && (
                  <Icon
                    alt="menu"
                    className="cursor-pointer"
                    icon="menu"
                    onClick={() => uiStore.setMenuOpen(true)}
                    // {...uiStore.refreshing === true && { id: 'refresh' }}
                    style={{ marginLeft: '1em' }}
                    // id="refresh"
                  />
                )} */}
              </TopAppBarSection>
              <img src={logo} alt="logo" style={{ width: '150px' }} />
              <TopAppBarSection alignEnd>
                {/* { uiStore.currentUser != null && (
                <Icon
                  alt="logout"
                  className="cursor-pointer"
                  icon="power_settings_new"
                  onClick={() => loginStore.logout()}
                  style={{ paddingRight: '1em' }}
                />
                )} */}

                {/* {uiStore.currentUser && uiStore.currentUser?.role !== 'employer'
                  && (
                    <Icon
                      alt="logout"
                      className="cursor-pointer"
                      icon="power_settings_new"
                      onClick={() => loginStore.logout()}
                      style={{ paddingRight: '1em' }}
                    />
                  )}
                {uiStore.currentUser?.role === 'employer'
                && ( */}

                { uiStore.currentUser != null && (
                  <Icon
                    alt="refresh"
                    className="cursor-pointer"
                    icon="refresh"
                    // onClick={() => this.refreshData()}
                    onClick={() => this.refreshPage()}
                    {...uiStore.refreshing === true && { id: 'refresh' }}
                    style={{ marginRight: '1em' }}
                    // id="refresh"
                  />
                )}
              </TopAppBarSection>
            </TopAppBarRow>
            {(header == null && view.needsHeader === true) && (
              <Header tabIndex={tabIndex} onTabSelected={this.onTabSelected} onSafetyTabSelected={this.onSafetyTabSelected} drawerTabIndex={drawerTabIndex} />
            )}
            {header != null && header}
          </TopAppBar>
        )}

        {/* { uiStore.currentUser?.role === 'employer' && ( */}
        <Drawer
          modal={uiStore.mobileMode || !uiStore.employerMode}
          open={uiStore.menuOpen}
          onClose={() => uiStore.setMenuOpen(false)}
          className={
            uiStore.employerMode && !uiStore.employerMiniMenuLocked
              ? 'employer-side-menu side-menu'
              : 'side-menu'
          }
        >
          <DrawerHeader className={uiStore.employerMode ? 'employer-side-menu-header side-menu-header' : 'side-menu-header'}>
            <DrawerTitle>
              {/* Show the avatar + name in the menu in the mobile mode */}
              {(uiStore.mobileMode || !uiStore.employerMode) && (
                <>
                  <Avatar
                    src={uiStore.currentUser?.profilePictureURL}
                    name={`${uiStore.currentUser?.firstName} ${uiStore.currentUser?.lastName}`}
                    size="xlarge"
                    style={{ verticalAlign: 'unset' }}
                  />
                  <span style={{ marginLeft: '5px' }}>
                    {`${uiStore.currentUser?.firstName} ${uiStore.currentUser?.lastName}`}
                  </span>
                </>
              )}
              {/* Show the prokalenteri logo in the menu in the mobile mode, in desktop mode switch between the tw */}
              {(!uiStore.mobileMode && uiStore.employerMode) && (
                <div className="drawer-logo-container">
                  <img
                    src={logo}
                    alt="large logo"
                    className={`large-logo ${uiStore.employerMiniMenuLocked ? 'locked' : ''}`}
                  />
                  {!uiStore.employerMiniMenuLocked && (
                    <img
                      src={smallLogo}
                      alt="small logo"
                      className="small-logo"
                    />
                  )}
                </div>
              )}
            </DrawerTitle>
            {/* <DrawerSubtitle>Subtitle</DrawerSubtitle> */}
          </DrawerHeader>

          {!uiStore.employerMode && uiStore.currentUser?.accounts && uiStore.currentUser?.accounts.length > 1 && (
            <Select
              key="account-select"
              className="mdc-theme--secondary-bg pk-select"
              // label="Nykyinen firma"
              onInput={(event) => loginStore.switchAccount(event.target.value, this.afterLogin)}
              onChange={(event) => loginStore.switchAccount(event.target.value, this.afterLogin)}
              // eslint-disable-next-line camelcase
              value={currentUser?.currentAccount?.account_id || '-'}
              style={{
                padding: '0 10px',
              }}
              options={accountSelectOptions}
              rootProps={{ ripple: false, style: { width: '100%' } }}
            />
          )}

          <DrawerContent className={uiStore.employerMode ? 'employer-side-menu-content side-menu-content' : 'side-menu-content'}>
            <List className="side-menu-list">
              {uiStore.currentUser?.role === 'employer' && (
                <>
                  <ListItem
                    onClick={() => uiStore.setEmployerMode(true)}
                    // style={{ color: view.name !== 'employer-timelog' ? 'var(--mdc-theme-primary)' : 'red' }}
                    className={view.name === 'employer-timelogs' || view.name === 'employer-work-orders' || view.name === 'employer-work-order' || view.name === 'employer-work-order-trips' || view.name === 'employer-absences' ? 'primary-text' : null}
                  >
                    <WorkOutlineIcon
                      className="MuiSvgIcon-root"
                      style={{ marginRight: 8 }}
                    />
                    {i18n.t('view_titles.employer-timelog')}
                  </ListItem>

                  <ListItem
                    onClick={() => uiStore.showEmployerBilling()}
                    // style={{ color: view.name !== 'employer-timelog' ? 'var(--mdc-theme-primary)' : 'red' }}
                    className={view.name === 'employer-billing' || view.name === 'employer-billing-attachments' || view.name === 'employer-billing-reports' || view.name === 'employer-billing-reports-hourly' || view.name === 'employer-product-report' ? 'primary-text' : null}
                  >
                    <ReceiptLong
                      className="MuiSvgIcon-root"
                      style={{ marginRight: 8 }}
                    />
                    {i18n.t('view_titles.employer-billing')}
                  </ListItem>

                  <ListItem
                    onClick={() => uiStore.showResourceAvailability()}
                    // style={{ color: view.name !== 'employer-timelog' ? 'var(--mdc-theme-primary)' : 'red' }}
                    className={view.name === 'resource-availability' ? 'primary-text' : null}
                  >
                    <EventNoteOutlinedIcon
                      className="MuiSvgIcon-root"
                      style={{ marginRight: 8 }}
                    />
                    {i18n.t('view_titles.resource-availability')}
                  </ListItem>

                  <ListItem
                    onClick={() => uiStore.showLocationsPurchasers()}
                    // style={{ color: view.name !== 'employer-timelog' ? 'var(--mdc-theme-primary)' : 'red' }}
                    className={view.name === 'locations-purchasers' ? 'primary-text' : null}
                  >
                    <FactoryOutlinedIcon
                      className="MuiSvgIcon-root"
                      style={{ marginRight: 8 }}
                    />
                    {i18n.t('view_titles.locations-purchasers')}
                  </ListItem>

                  <ListItem
                    onClick={() => uiStore.showEmployees()}
                    // style={{ color: view.name !== 'employer-timelog' ? 'var(--mdc-theme-primary)' : 'red' }}
                    className={view.name === 'employees' ? 'primary-text' : null}
                  >
                    <PeopleAltOutlinedIcon
                      className="MuiSvgIcon-root"
                      style={{ marginRight: 8 }}
                    />
                    {i18n.t('view_titles.employees')}
                  </ListItem>

                  {uiStore.currentUser.accountInfo.productsEnabled && (
                    <ListItem
                      onClick={() => uiStore.showProducts()}
                      // style={{ color: view.name !== 'employer-timelog' ? 'var(--mdc-theme-primary)' : 'red' }}
                      className={view.name === 'products' ? 'primary-text' : null}
                    >
                      <InventoryIcon
                        className="MuiSvgIcon-root"
                        style={{ marginRight: 8 }}
                      />
                      {i18n.t('view_titles.products')}
                    </ListItem>
                  )}

                  {uiStore.currentUser.accountInfo.certificatesEnabled && (
                    <ListItem
                      onClick={() => uiStore.showCertificates()}
                      // style={{ color: view.name !== 'employer-timelog' ? 'var(--mdc-theme-primary)' : 'red' }}
                      className={view.name === 'certificates' ? 'primary-text' : null}
                    >
                      <CertificateIcon
                        className="MuiSvgIcon-root"
                        style={{ marginRight: 8 }}
                      />
                      {i18n.t('view_titles.certificates')}
                    </ListItem>
                  )}

                  {uiStore.currentUser.accountInfo.bulletinBoardEnabled && (
                    <ListItem
                      onClick={() => uiStore.showBulletinBoardPosts()}
                      // style={{ color: view.name !== 'employer-timelog' ? 'var(--mdc-theme-primary)' : 'red' }}
                      className={view.name === 'bulletin-board-posts' ? 'primary-text' : null}
                    >
                      <AssignmentOutlinedIcon
                        className="MuiSvgIcon-root"
                        style={{ marginRight: 8 }}
                      />
                      {i18n.t('view_titles.bulletin-board-posts')}
                    </ListItem>
                  )}

                  {/* {(uiStore.currentUser.accountId === 3 || uiStore.currentUser.accountId === 10 || uiStore.currentUser.accountId === 49 || uiStore.currentUser.accountId === 51 || uiStore.currentUser.accountId === 52 || uiStore.currentUser.accountId === 53 || uiStore.currentUser.accountId === 44 || uiStore.currentUser.accountId === 50 || uiStore.currentUser.accountId === 54 || uiStore.currentUser.accountId === 40 || uiStore.currentUser.accountId === 55 || uiStore.currentUser.accountId === 56 || uiStore.currentUser.accountId === 57) && ( */}
                  {(!accountsWithoutSalaryPeriodProcessing.includes(uiStore.currentUser.accountId)) && (
                    <ListItem
                      onClick={() => uiStore.showSalaryPeriodProcessing()}
                      className={view.name === 'salary-period-processing' ? 'primary-text' : null}
                    >
                      <AccountBalanceOutlinedIcon
                        className="MuiSvgIcon-root"
                        style={{ marginRight: 8 }}
                      />
                      {i18n.t('view_titles.payroll-management')}
                    </ListItem>
                  )}

                  <div
                    style={{
                      borderTop: '1px solid gray',
                      width: '90%',
                      alignSelf: 'center',
                    }}
                  />
                </>
              )}

              <ListItem
                onClick={() => uiStore.setEmployerMode(false)}
                // DO SOMETHING ABOUT THIS, even an array with an .includes check would be better than this
                className={view.name === 'calendar' ? 'primary-text' : null}
              >
                <CalendarTodayOutlinedIcon
                  className="MuiSvgIcon-root"
                  style={{ marginRight: 8 }}
                />
                {i18n.t('view_titles.calendar')}
              </ListItem>

              <ListItem
                onClick={() => {
                  uiStore.setEmployerMode(false);
                  uiStore.showProfile();
                }}
                // DO SOMETHING ABOUT THIS, even an array with an .includes check would be better than this
                className={view.name === 'profile' ? 'primary-text' : null}
              >
                <AccountCircleOutlinedIcon
                  className="MuiSvgIcon-root"
                  style={{ marginRight: 8 }}
                />
                {i18n.t('view_titles.profile')}
              </ListItem>

              <ListItem
                onClick={() => {
                  uiStore.setEmployerMode(false);
                  uiStore.showTimeEntries();
                }}
                // DO SOMETHING ABOUT THIS, even an array with an .includes check would be better than this
                className={view.name === 'timelog' ? 'primary-text' : null}
              >
                <ScheduleIcon
                  className="MuiSvgIcon-root"
                  style={{ marginRight: 8 }}
                />
                {i18n.t('view_titles.timelog')}
              </ListItem>

              <ListItem
                onClick={() => {
                  uiStore.setEmployerMode(false);
                  uiStore.showMessages();
                }}
                // DO SOMETHING ABOUT THIS, even an array with an .includes check would be better than this
                className={view.name === 'messages' || view.name === 'chat-room' || view.name === 'bulletin-board-category' ? 'primary-text' : null}
              >
                <MessageOutlinedIcon
                  className="MuiSvgIcon-root"
                  style={{ marginRight: 8 }}
                />
                {i18n.t('view_titles.messages')}
              </ListItem>

              {/* {(uiStore.currentUser?.accountId === 1 || uiStore.currentUser?.accountId === 10 || uiStore.currentUser?.accountId === 3) && ( */}
              {uiStore.currentUser?.accountInfo?.closeCallReportsEnabled && (
                <ListItem
                  onClick={() => {
                    uiStore.setEmployerMode(false);
                    uiStore.showSafety();
                  }}
                  // style={{ color: view.name !== 'employer-timelog' ? 'var(--mdc-theme-primary)' : 'red' }}
                  className={view.name === 'safety' || view.name === 'employer-close-call-reports' ? 'primary-text' : null}
                >
                  <ReportProblemOutlinedIcon
                    className="MuiSvgIcon-root"
                    style={{ marginRight: 8 }}
                  />
                  {i18n.t('view_titles.safety')}
                </ListItem>
              )}

              {uiStore.currentUser?.accountInfo?.accidentReportsEnabled && (
                <ListItem
                  onClick={() => {
                    uiStore.setEmployerMode(false);
                    uiStore.showAccidentReports();
                  }}
                  // style={{ color: view.name !== 'employer-timelog' ? 'var(--mdc-theme-primary)' : 'red' }}
                  className={view.name === 'accident-reports' ? 'primary-text' : null}
                >
                  <HealingOutlinedIcon
                    className="MuiSvgIcon-root"
                    style={{ marginRight: 8 }}
                  />
                  {i18n.t('view_titles.accident-reports')}
                </ListItem>
              )}

              <div className="bottom-item">
                {/* NOTE: Due to flex-direction: column-reverse these will be in reverse order */}
                {/* Should be fixed if a better way to position these to the bottom of the list is found */}
                <ListItem onClick={() => loginStore.logout()} style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  <ExitToAppOutlinedIcon
                    className="MuiSvgIcon-root"
                    style={{ marginRight: 8 }}
                  />
                  {i18n.t('profile.labels.logout')}
                </ListItem>
                {(!uiStore.mobileMode && uiStore.employerMode) && (
                  <div className={`lock-icon ${isMenuLocked ? 'locked' : 'hoverable'}`}>
                    <Tooltip
                      title={isMenuLocked ? 'Pienennä navigointi' : 'Pidä navigointi avoinna'}
                      placement="right"
                      arrow
                      PopperProps={{
                        modifiers: {
                          offset: {
                            enabled: true,
                            offset: '0, 7', // Shift the tooltip by 7px vertically
                          },
                        },
                      }}
                    >
                      <IconButton onClick={() => uiStore.toggleMenuLock()} style={{ padding: '12px' }}>
                        {isMenuLocked ? (
                          <ArrowBackIosOutlinedIcon />
                        ) : (
                          <SideBarIcon style={{ transform: 'rotate(180deg)' }} />
                        )}
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            </List>
          </DrawerContent>
        </Drawer>
        {/* )} */}

        <main
          className={contentClasses.join(' ')}
          // style={{ marginTop: appContentMarginTop }}
        >
          {/* DESKTOP HEADER */}
          {!uiStore.mobileMode && uiStore.employerMode && view.needsHeader && (
            <>
              <div
                style={{
                  // boxShadow: '0px 4px 1px -1px rgb(0 0 0 / 20%), 0px 2px 1px 0px rgb(0 0 0 / 14%), 0px 2px 3px 0px rgb(0 0 0 / 12%)',
                  boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
                  width: '100%',
                  backgroundColor: '#D8D8D8',
                  height: '50px',
                  minHeight: '50px',
                  fontSize: '14px',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'end',
                  justifyContent: 'center',
                  color: 'black',
                }}
              >
                {uiStore.currentUser && (
                  <div>
                    <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                      {`${uiStore.currentUser?.firstName} ${uiStore.currentUser?.lastName}`}
                    </span>
                    <Avatar
                      src={uiStore.currentUser?.profilePictureURL}
                      name={`${uiStore.currentUser?.firstName} ${uiStore.currentUser?.lastName}`}
                      size="large"
                      style={{ verticalAlign: 'unset', marginRight: '20px' }}
                    />
                  </div>
                )}
              </div>

              {/* DESKTOP PLACEHOLDER HEADER (RECYCLED MOBILE HEADER) */}
              <TopAppBar style={{ position: 'unset' }}>
                {(header == null && view.needsHeader === true) && (
                  <Header tabIndex={tabIndex} onTabSelected={this.onTabSelected} drawerTabIndex={drawerTabIndex} />
                )}
                {header != null && header}
              </TopAppBar>
            </>
          )}

          {contentView}

          <Snackbar
            className="global-snackbar"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={snackbarOnClose}
            message={snackbarMessage}
            key="global-snackbar"
            autoHideDuration={3000}
          />
        </main>
      </>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <Theme>
        <Provider {...stores} t={t}>
          {this.renderApp()}
        </Provider>
      </Theme>
    );
  }
}

export default withTranslation()(App);
